import React, { useEffect } from 'react';
import { Image, Input, Form, Modal, Button, Upload, DatePicker, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { keys, some } from 'lodash/fp'
import styles from './ClaimDetail.module.scss';
import { CLAIM_STATUS_TH, REJECT_REASONS_TH, BANK} from '../utils/constant';

const { Option } = Select;

const BankEdit = ({
  claim,
  documents,
  handleUpdate,
  isShowModal,
  setIsShowModal,
}) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    const fields = await form.validateFields()
    const bankAccountName = fields.bank_account_name.toLowerCase()
    if(!claim.insuree_name.toLowerCase().split(' ')[0].includes(bankAccountName.split(' ')[0])) {
      setIsShowModal(true)
      return
    }
    await onUpdate()
  }

  const onUpdate = async () => {
    const fields = await form.validateFields()
    await handleUpdate({
      claimId: claim.id,
      bankAccountName: fields.bank_account_name,
      bankAccountNumber: fields.bank_account_number,
      bankName: fields.bank_name,
      bankConfirmationFile: fields.bank_confirmation_file,
      bankAccountRemarks: fields.bank_account_remarks,
    });
  }

  useEffect(() => {
    form.setFieldsValue({
      bank_name: some((x) => x === claim?.insuree_bank_name, keys(BANK)) ? claim?.insuree_bank_name : '',
      bank_account_number: claim?.insuree_bank_account,
      bank_account_name: claim?.insuree_bank_account_name,
    });
  }, [form, claim]);

  return (
  <>
    <Modal
      visible={isShowModal}
      onCancel={() => setIsShowModal(false)}
      onOk={() => onUpdate()}
    >
      ชื่อบัญชีไม่ตรงกับ ชื่อผู้เอาประกัน ยืนยันทำรายการต่อ?
    </Modal>
    <div className={styles.claimDetailRoot}>
      <div className={styles.pageTitle}>
        {'เคลม' /* Claim */ } #{claim?.id}
      </div>
      <div>{'ผู้ทำเคลม' /* Assignee */ }: {claim?.assignee || '-'}</div>
      <div>{'สร้างโดย' /* Created By */ }: {claim?.submitter_email}</div>
      <div className={styles.claimInfo}>
        <div>
          <div className={styles.infoGroupTitle}>{'ข้อมูลเคลม' /* Claim Detail */ }</div>
          <div className={styles.infoKey}>{'เลข Ref.' /* Reference number */ }</div>
          <div className={styles.infoValue}>{claim?.reference_number}</div>
          <div className={styles.infoKey}>{'สถานะ' /* Status */ }</div>
          <div className={styles.infoValue}>{CLAIM_STATUS_TH[claim?.status] || claim?.status}</div>
          <div className={styles.infoKey}>{'วันรักษา' /* Treatement Date */ }</div>
          <div className={styles.infoValue}>{claim?.treatment_date}</div>
          <div className={styles.infoKey}>{'ยอดเคลม' /* Claimed Amount */}</div>
          <div className={styles.infoValue}>{claim?.claimed_amount_formatted}</div>
          <div className={styles.infoKey}>{'สร้างเมื่อ' /* Submitted Date */ }</div>
          <div className={styles.infoValue}>{claim?.submitted_date}</div>
          <div className={styles.infoKey}>{'เหตุผลการยกเลิก' /* Reject Reason */ }</div>
          <div className={styles.infoValue}>{REJECT_REASONS_TH[claim?.reject_reason] || claim?.reject_reason || '-'}</div>
          <div className={styles.infoKey}>{'remarks'}</div>
          <div className={styles.infoValue}>{claim?.remarks || '-'}</div>
        </div>
        <div>
          <div className={styles.infoGroupTitle}>{'ข้อมูลกรมธรรม์' /* Policy Detail */ }</div>
          <div className={styles.infoKey}>{'เลขกรมธรรม์' /* Policy Number */ }</div>
          <div className={styles.infoValue}>{claim?.policy_number}</div>
          <div className={styles.infoKey}>{'ชื่อผู้ถือกรมธรรม์' /* Policy Holder Name */ }</div>
          <div className={styles.infoValue}>{claim?.policy_holder_name}</div>
          <div className={styles.infoGroupTitle}>{'ผู้รับความคุ้มครอง' /* Insured */ }</div>
          <div className={styles.infoKey}>{'ชื่อ' /* Name */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_name}</div>
          <div className={styles.infoKey}>{'เลขบัตรปชช.' /* Identification number */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_id}</div>
          <div className={styles.infoKey}>{'โทรศัพท์' /* Phone */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_contact}</div>
          <div className={styles.infoKey}>{'อีเมลล์' /* Email */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_email}</div>
          <div className={styles.infoKey}>{'รหัสธนาคาร' /* Bank Name */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_bank_name || '-'}</div>
          <div className={styles.infoKey}>{'เลขบัญชีธนาคาร' /* Bank Account */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_bank_account || '-'}</div>
          <div className={styles.infoKey}>{'ชื่อบัญชีธนาคาร' /* Bank Account Name */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_bank_account_name || '-'}</div>
        </div>
        <div>
        <div className={styles.infoGroupTitle}>{'อัพเดทข้อมูลบัญชี' /* Policy Detail */ }</div>
          <Form layout='vertical' onFinish={() => {}} form={form}>
            <Form.Item
              label="รหัสธนาคาร" // bank name
              name="bank_name"
            >
              <Input disabled/>
            </Form.Item>

            <Form.Item
              label="ชื่อธนาคาร" // bank name
              name="bank_name"
              rules={[{ required: true, message: 'ชื่อธนาคาร ห้ามเป็นค่าว่าง' }]}
            >
              <Select
                placeholder="เลือกธนาคาร"
                allowClear
              >
                {
                  keys(BANK).map(
                    (code) => (
                      <Option key={code} value={code}>{BANK[code].name.localize.th}</Option>
                    )
                  )
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="ชื่อบัญชีธนาคาร" // bank account name
              name="bank_account_name"
              rules={[{ required: true, message: 'ชื่อบัญชีธนาคาร ห้ามเป็นค่าว่าง' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="เลขบัญชีธนาคาร" // bank account number
              name="bank_account_number"
              rules={[{
                required: true,
                message: 'เลขบัญชีธนาคาร ห้ามเป็นค่าว่าง'
              }, {
                pattern: /^[0-9]*$/,
                message: "ต้องเป็นตัวเลขเท่านั้น"
              }, 
                ({getFieldValue}) => ({
                  validator(_, value) {
                      const bankName = getFieldValue('bank_name')
                      const bankWith10Digits = [
                        'BBL', 'KBANK', 'KTB', 'SCB', 'TTB', 'CITI', 'CIMB', 'SC',
                        'UOBT', 'BAY', 'ISBT', 'LHB', 'KKP'
                      ]
                      if (
                        (some((x) => x === bankName, bankWith10Digits) && value.length != 10) ||
                        ((bankName === 'BAAC' || bankName === 'GHB') && value.length != 12) ||
                        ((bankName === 'TISCO') && value.length != 14) ||
                        (bankName === 'GSB' && !(value.length === 12 || value.length === 15))
                      ) {
                        return Promise.reject('Format เลขบัญชีไม่ถูกต้อง');
                      }
                      return Promise.resolve();
                  }})
            ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="หลักฐานยืนยันข้อมูลธนาคาร"
              name="bank_confirmation_file"
              rules={[{ required: true, message: 'กรุณาอัพโหลด หลักฐานยืนยันข้อมูลธนาคาร' }]}
              
            >
              <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
                <Button icon={<UploadOutlined/>}>เลือกไฟล์</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="หมายเหตุ"
              name="bank_account_remarks"
            >
              <Input.TextArea/>
            </Form.Item>
            <Button onClick={() => onSubmit()}>Update</Button>
          </Form>
        </div>
      </div>
      <div>
        <div className={styles.contentBoxLabel}>{'เอกสาร' /* Documents */ }</div>
        <div className={styles.documentsBox}>
          <Image.PreviewGroup>
            {documents.map(i => {
              return (
                <span className={styles.docWrapper}>
                  <Image
                    key={i.title}
                    src={i.url}
                    className={styles.docImg}
                  />
                  <div>{i.title}</div>
                  <a href={i.url} download>ดาวโหลด</a>
                </span>
              )
            })}
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    </>
  );
};

export default BankEdit;