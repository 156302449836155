import React, { useState } from 'react';
import { Image, Input, InputNumber, Form, Modal, Button, Space, DatePicker, Select, Checkbox, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';

import moment from 'moment';

import styles from './ClaimDetail.module.scss';
import { CLAIM_STATUS, CLAIM_STATUS_TH, REJECT_REASONS, REJECT_REASONS_TH, MISSING_DOC, MISSING_DOC_TH } from '../utils/constant';

const { Option } = Select;

const ClaimDetail = ({
  claim,
  documents,
  updateClaimHandler,
  resendTPAHandler,
  editBankHandler,
  confirmRejectHandler,
  downloadRejectLetterHandler,
  previewRejectLetterHandler,
  userRole,
}) => {
  const [cancelForm] = Form.useForm();
  const [missingDocForm] = Form.useForm();
  const [rejectReasonForm] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelFormVisible, setCancelFormVisible] = useState(false);
  const [otherBoxVisible, setOtherBoxVisible] = useState(false);
  const [missingDocVislble, setMissingDocVisible] = useState(false);
  const [missingOtherChecked, setMissingOtherChecked] = useState(false);

  const handleEditBtn = () => {
    setIsModalOpen(true);
  };

  const handleCancelEdit = () => {
    setIsModalOpen(false);
  };

  const closeCancelForm = () => {
    setCancelFormVisible(false);
  };

  const openCancelForm = () => {
    setCancelFormVisible(true);
  };

  const openDocMissingForm = () => {
    setMissingDocVisible(true);
  };

  const closeMissingForm = () => {
    setMissingDocVisible(false);
  };

  const onConfirmReject = async () => {
    const fields = await rejectReasonForm.validateFields()
    await confirmRejectHandler(fields.reason, fields.reject_confirmation_file)
  }

  const onPreview = async() => {
    await previewRejectLetterHandler(rejectReasonForm.getFieldValue('reason'))
  }

  const onOkMissingDoc = async () => {
    try {
      const fields = await missingDocForm.validateFields();

      const remarks = fields.missing_docs.filter(i => i !== MISSING_DOC.OTHER);
      fields?.extra?.forEach(i => remarks.push(`OTHER:${i}`));

      await updateClaimHandler({
        status: CLAIM_STATUS.REJECTED,
        reject_reason: REJECT_REASONS.DOCUMENT_INCOMPLETE,
        remarks: JSON.stringify(remarks)
      });
    } catch(err) {
      console.error(err);
    }
  }

  const onOkCancelForm = async () => {
    try {
      const fields = await cancelForm.validateFields()
      await updateClaimHandler({
        status: CLAIM_STATUS.CANCELLED,
        reject_reason: fields.reason,
        remarks: fields.remarks || ''
      });
    } catch(err) {
      console.error(err);
    }
  };

  const rejectReasonsHandler = (value) => setOtherBoxVisible(value === REJECT_REASONS.OTHER);

  const approveBtn = (<Button type="default" onClick={() => updateClaimHandler({ status: CLAIM_STATUS.DOCUMENT_APPROVED })}>{'อนุมัติเอกสาร' /* Approve Document */ }</Button>);

  return (
    <>
    <Modal
      visible={cancelFormVisible}
      onCancel={closeCancelForm}
      onOk={onOkCancelForm}
    >
      <Form layout='vertical' form={cancelForm}>
        <Form.Item
          label="เหตุผล" // reason
          name="reason"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="เลือกเหตุผล"
            allowClear
            onChange={rejectReasonsHandler}
          >
            <Option value={REJECT_REASONS.NOT_COVERED}>{REJECT_REASONS_TH.NOT_COVERED}</Option>
            <Option value={REJECT_REASONS.EXCEPTION}>{REJECT_REASONS_TH.EXCEPTION}</Option>
            <Option value={REJECT_REASONS.FAX_CLAIM}>{REJECT_REASONS_TH.FAX_CLAIM}</Option>
            <Option value={REJECT_REASONS.OTHER}>{REJECT_REASONS_TH.OTHER}</Option>
          </Select>
        </Form.Item>
        {
          otherBoxVisible ? (
            <Form.Item
              label="โปรดระบุ" // reason
              name="remarks"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          ) : null
        }
      </Form>
    </Modal>
    <Modal
      visible={missingDocVislble}
      onCancel={closeMissingForm}
      onOk={onOkMissingDoc}
    >
      <Form layout="vertical" form={missingDocForm}>
        <Form.Item
          label="เอกสารไม่ครบ"
          name="missing_docs"
          rules={[{ required: true }]}
        >
          <Checkbox.Group>
            <div><Checkbox value={MISSING_DOC.BOOKBANK}>{MISSING_DOC_TH.BOOKBANK}</Checkbox></div>
            <div><Checkbox value={MISSING_DOC.RECEIPT}>{MISSING_DOC_TH.RECEIPT}</Checkbox></div>
            <div><Checkbox value={MISSING_DOC.MEDICINE_SACHET}>{MISSING_DOC_TH.MEDICINE_SACHET}</Checkbox></div>
            <div><Checkbox value={MISSING_DOC.OTHER} onChange={(e) => setMissingOtherChecked(e.target.checked)}>{MISSING_DOC_TH.OTHER}</Checkbox></div>
          </Checkbox.Group>
        </Form.Item>
        {missingOtherChecked && (
          <Form.List name="extra" initialValue={['']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({key, name, ...field}, idx) => (
                  <div className={styles.extraDocWrap}>
                    <Form.Item
                      {...field}
                      name={name}
                      rules={[{ required: true }]}
                      className={styles.extraDocInpu}
                    >
                      <Input placeholder="ใส่ชื่อเอกสาร"/>
                    </Form.Item>
                    {idx !== 0 && <MinusCircleOutlined onClick={() => remove(name)} className={styles.extraDocDel}/>}
                  </div>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}>
                    ระบุเอกสารเพิ่มเติม
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
      </Form>
    </Modal>
    <Modal visible={isModalOpen} onCancel={handleCancelEdit}>
      <Form layout='vertical' onFinish={() => {}}>
        <Form.Item
          label="เลขกรมธรรม์" // policy number
          name="policy_number"
          rules={[{ required: true }]}
        >
          <Input defaultValue={claim?.policy_number}/>
        </Form.Item>
        {/* <Form.Item
          label="type"
          name="type"
        >
          <Input defaultValue={claim?.type}/>
        </Form.Item> */}
        <Form.Item
          label="ผู้ทำประกัน" // insured name
          name="insured_name"
          rules={[{ required: true }]}
        >
          <Input defaultValue={claim?.insuree_name}/>
        </Form.Item>
        {/* <Form.Item
          label="insured nationality"
          name="insured_nationality"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label="เลขบัตรปชช." // insured id
          name="insured_id"
          rules={[{ required: true }]}
        >
          <Input defaultValue={claim?.insuree_id}/>
        </Form.Item>
        <Form.Item
          label="โทรศัพท์" // phone
          name="phone"
          rules={[{ required: true }]}
        >
          <Input defaultValue={claim?.insuree_contact}/>
        </Form.Item>
        <Form.Item
          label="อีเมล" // email
          name="email"
          rules={[{ required: true }]}
        >
          <Input defaultValue={claim?.insuree_email}/>
        </Form.Item>
        <Form.Item
          label="ยอดเคลม" // claimed amount
          name="claimed_amount"
          rules={[{ required: true }]}
        >
          <InputNumber
            prefix="฿"
            controls={false}
            min={0}
            precision={2}
            defaultValue={claim?.claimed_amount}
          />
        </Form.Item>
        <Form.Item
          label="วันเริ่มรักษา" // treatment start
          name="treatment_start"
          rules={[{ required: true }]}
        >
          <DatePicker defaultValue={moment(claim?.treatment_start, 'YYYY-MM-DD')}/>
        </Form.Item>
        <Form.Item
          label="วันสิ้นสุดการรักษา" // treatment end
          name="treatment_end"
          rules={[{ required: true }]}
        >
          <DatePicker defaultValue={moment(claim?.treatment_end, 'YYYY-MM-DD')}/>
        </Form.Item>
        <Form.Item
          label="ชื่อธนาคาร" // bank name
          name="bank_name"
        >
          <Input defaultValue={claim?.insuree_bank_name}/>
        </Form.Item>
        <Form.Item
          label="ชื่อบัญชีธนาคาร" // bank account name
          name="bank_account_name"
        >
          <Input defaultValue={claim?.insuree_bank_account_name}/>
        </Form.Item>
        <Form.Item
          label="เลขบัญชีธนาคาร" // bank account number
          name="bank_account_number"
        >
          <Input defaultValue={claim?.insuree_bank_account}/>
        </Form.Item>
      </Form>
    </Modal>
    <div className={styles.claimDetailRoot}>
      <div id="pdfContainer"/>
      <div className={styles.pageTitle}>
        {'เคลม' /* Claim */ } #{claim?.id}
        {/* {editBtnVisible && <Button type="link" size='small' onClick={handleEditBtn}>Edit</Button>} */}
      </div>
      <div>{'ผู้ทำเคลม' /* Assignee */ }: {claim?.assignee || '-'}</div>
      <div>{'สร้างโดย' /* Created By */ }: {claim?.submitter_email}</div>
      <div className={styles.claimInfo}>
        <div>
          <div className={styles.infoGroupTitle}>{'การอนุมัติ' /* Approval */ } </div>
            {
              claim?.status === CLAIM_STATUS.SUBMITTED ?
              <Space  direction="vertical">
                {approveBtn}
                <Button type="default" onClick={() => openCancelForm()}>{'ยกเลิก' /* Cancel Claim */ }</Button>
                <Button type="default" onClick={() => openDocMissingForm()}>{'เอกสารไม่ครบ'}</Button>
              </Space>
              :
              <>
                <div>{CLAIM_STATUS_TH[claim?.status] || claim?.status}</div>
                {claim?.status === CLAIM_STATUS.DOCUMENT_INCOMPLETE && approveBtn}
              </>
            }
            {
              claim?.status === CLAIM_STATUS.PENDING_SEND_TO_SUNCARE && (
                <Button type="default" onClick={() => resendTPAHandler()}>{'Resend to TPA'}</Button>
              )
            }
            {
              claim?.status === CLAIM_STATUS.INCOMPLETE_BANK_DETAIL && (
                <Button type="default" onClick={() => editBankHandler()}>{'เพิ่มเลขบัญชี'}</Button>
              )
            }
        </div>
        <div>
          <div className={styles.infoGroupTitle}>{'ข้อมูลเคลม' /* Claim Detail */ }</div>
          <div className={styles.infoKey}>{'เลข Ref.' /* Reference number */ }</div>
          <div className={styles.infoValue}>{claim?.reference_number}</div>
          <div className={styles.infoKey}>{'เลข เคลม TPA' /* Reference number */ }</div>
          <div className={styles.infoValue}>{claim?.tpa_claim_number}</div>
          <div className={styles.infoKey}>{'สถานะ' /* Status */ }</div>
          <div className={styles.infoValue}>{CLAIM_STATUS_TH[claim?.status] || claim?.status}</div>
          <div className={styles.infoKey}>{'ช่องทางยื่นเคลม' /* Channel */ }</div>
          <div className={styles.infoValue}>{claim?.channel}</div>
          <div className={styles.infoKey}>{'วันรักษา' /* Treatement Date */ }</div>
          <div className={styles.infoValue}>{claim?.treatment_date}</div>
          <div className={styles.infoKey}>{'ยอดเคลม' /* Claimed Amount */}</div>
          <div className={styles.infoValue}>{claim?.claimed_amount_formatted}</div>
          <div className={styles.infoKey}>{'สร้างเมื่อ' /* Submitted Date */ }</div>
          <div className={styles.infoValue}>{claim?.submitted_date}</div>
          <div className={styles.infoKey}>{'เหตุผลการยกเลิก' /* Reject Reason */ }</div>
          <div className={styles.infoValue}>{REJECT_REASONS_TH[claim?.reject_reason] || claim?.reject_reason || '-'}</div>
          <div className={styles.infoKey}>{'remarks'}</div>
          <div className={styles.infoValue}>{claim?.remarks || '-'}</div>
        </div>
        <div>
          <div className={styles.infoGroupTitle}>{'ผู้รับความคุ้มครอง' /* Insured */ }</div>
          <div className={styles.infoKey}>{'ชื่อ' /* Name */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_name}</div>
          <div className={styles.infoKey}>{'เลขบัตรปชช.' /* Identification number */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_id}</div>
          <div className={styles.infoKey}>{'โทรศัพท์' /* Phone */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_contact}</div>
          <div className={styles.infoKey}>{'อีเมลล์' /* Email */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_email}</div>
          <div className={styles.infoKey}>{'ชื่อธนาคาร' /* Bank Name */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_bank_name || '-'}</div>
          <div className={styles.infoKey}>{'เลขบัญชีธนาคาร' /* Bank Account */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_bank_account || '-'}</div>
          <div className={styles.infoKey}>{'ชื่อบัญชีธนาคาร' /* Bank Account Name */ }</div>
          <div className={styles.infoValue}>{claim?.insuree_bank_account_name || '-'}</div>
        </div>
        <div>
          <div className={styles.infoGroupTitle}>{'ข้อมูลกรมธรรม์' /* Policy Detail */ }</div>
          <div className={styles.infoKey}>{'เลขกรมธรรม์' /* Policy Number */ }</div>
          <div className={styles.infoValue}>{claim?.policy_number}</div>
          <div className={styles.infoKey}>{'ชื่อผู้ถือกรมธรรม์' /* Policy Holder Name */ }</div>
          <div className={styles.infoValue}>{claim?.policy_holder_name}</div>
        </div>
      </div>
      {
        (claim?.status === CLAIM_STATUS.PENDING_REJECT || claim?.status === CLAIM_STATUS.REJECTED) &&
        (<div>
          <div className={styles.infoGroupTitle}>{'Reject Reason' }</div>
          <Form
            form={rejectReasonForm}
            initialValues={{
              reason: claim?.reject_request?.reject_reason
            }}
          >
            <Form.Item
              label="Reject Reason" // reason
              name="reason"
              rules={[{ required: true }]}
            >
              <Input.TextArea disabled={userRole !== 'CLAIM_ADMIN' || claim?.status === CLAIM_STATUS.REJECTED} />
            </Form.Item>
            {
              claim?.status !== CLAIM_STATUS.REJECTED &&
              (<Form.Item
                label="เอกสารยืนยันเหตุผลการ reject"
                name="reject_confirmation_file"
                rules={[{ required: true, message: 'กรุณาอัพโหลด เอกสารยืนยันเหตุผลการ reject' }]}
              >
                <Upload maxCount={1} listType="picture" customRequest={(cb) => cb.onSuccess()}>
                  <Button disabled={userRole !== 'CLAIM_ADMIN'} icon={<UploadOutlined/>}>เลือกไฟล์</Button>
                </Upload>
              </Form.Item>)
            }
            
            {
              claim?.status !== CLAIM_STATUS.REJECTED &&
              (<div>
                <Button disabled={userRole !== 'CLAIM_ADMIN'} type="default" onClick={() => onPreview() }>
                  Preview
                </Button>
                <span>         </span>
                <Button disabled={userRole !== 'CLAIM_ADMIN'} type="default" onClick={() => onConfirmReject() }>
                  ยืนยัน Reject Reason
                </Button>
              </div>)
            }
          </Form>

          {
              claim?.status === CLAIM_STATUS.REJECTED &&
              (<Button type="default" onClick={() => downloadRejectLetterHandler() }>
                Download reject letter
              </Button>)
            }
        </div>)
      }
      <div>
        <div className={styles.contentBoxLabel}>{'เอกสาร' /* Documents */ }</div>
        <div className={styles.documentsBox}>
          <Image.PreviewGroup>
            {documents.map(i => {
              return (
                <span className={styles.docWrapper}>
                  <Image
                    src={i.url}
                    className={styles.docImg}
                  />
                  <div>{i.title}</div>
                  <a href={i.url} download>ดาวโหลด</a>
                </span>
              )
            })}
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
    </>
  );
};

ClaimDetail.propTypes = {
  //claims: PropTypes.array
};

ClaimDetail.defaultProps = {
};

export default ClaimDetail;