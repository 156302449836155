import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from 'antd';
import { Link } from "react-router-dom";
import Logo from '../stories/assets/sunday-logo.svg';

import styles from './DefaultLayout.module.scss';

const DefaultLayout = ({ user, userNotFound, isSignedIn, onSignIn, onSignOut, ...props }) => {
  return (
    <>
      <div className={styles.navBar}>
        <Link to="/">
          <img src={Logo} alt="Sunday"/>
        </Link>
        <div className={styles.subNav}>
          { isSignedIn ?
            <>
              <div> {user?.displayName} </div>
              <Button type="" data-cy="SignOut" onClick={onSignOut}>sign-out</Button>
            </>
            :
            <Button type="primary" data-cy="SignIn" onClick={onSignIn}>sign-in</Button>
          }
        </div>
      </div>
      <>
        { userNotFound ?
          <Alert message="Account is not found. Please contact admin." type="error" />
          :
          <>
            { isSignedIn ?
              <>{props.children}</>
              :
              <center>
                <div> please sign-in </div>
              </center>
            }
          </>
        }
      </>
    </>
  );
};

DefaultLayout.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }),
  userNotFound: PropTypes.bool,
  isSignedIn: PropTypes.bool.isRequired,
  onSignIn: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
};

DefaultLayout.defaultProps = {
  userNotFound: false,
  isSignedIn: false
};

export default DefaultLayout;
