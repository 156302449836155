import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as dayjs from 'dayjs';
import axios from 'axios';
import Loading from '../components/Loading';
import IncompleteBankClaimList from "./IncompleteBankClaimList";

export const path = '/claims/incomplete-bank-detail';

const useIncompleteBankClaimListEntry = (props) => {
  const [claimData, setClaimData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    const { data: claimsData } = await axios.get(`/api/claims/incomplete-bank-detail`);

    setClaimData(claimsData.map((i) => ({
      submitted_at: dayjs(i.created_at).format('DD/MM/YYYY HH:mm:ss'),
      claim_number: i.id,
      policy_number: i.policy_number,
      policy_holder_name: i.policy_holder_name,
      insured_name: i.insured_name,
      insured_id: i.insured_id,
      treatment_date: dayjs(i.treatment_start).format('DD/MM/YYYY'),
      bank_account_name: i.bank_account_name,
      bank_account_number: i.bank_account_number,
      bank_name: i.bank_name,
      reference_number: i.reference_number,
      detail_link: `/claim/${i.id}/edit-bank`
    })));
    setIsLoading(false);
  }, [])

  return {
    claimData,
    isLoading,
  }
}

const IncompleteBankClaimListEntry = (props) => {
  const { claimData, isLoading } = useIncompleteBankClaimListEntry()
  return (
    <>
      {isLoading ? <Loading /> : null}
      <IncompleteBankClaimList claimData={claimData} />
    </>
  );
}

export default IncompleteBankClaimListEntry;