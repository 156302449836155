import React from 'react';
import PropTypes from 'prop-types';

import styles from './Loading.module.scss';

const Loading = () => {
  return (
    <>
      <div className={styles.background}></div>
      <div className={styles.loading}>loading...</div>
    </>
  );
};

Loading.propTypes = {
};

Loading.defaultProps = {
};

export default Loading;
