import { useEffect, useState, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signOut, signInWithPopup, signInWithCredential, onIdTokenChanged, GoogleAuthProvider } from "firebase/auth";
import { connectAuthEmulator } from "firebase/auth";
import axios from 'axios';
import isAbsoluteUrl from 'axios/lib/helpers/isAbsoluteURL.js';
import { notification } from 'antd';

import DefaultLayout from "./DefaultLayout";
import Loading from '../components/Loading';
import { LoginContext } from '../App';

// setup firebase
if (process.env.REACT_APP_AUTH_EMULATOR_URL) {
  initializeApp({ apiKey: 'fake_api_key' });
} else {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  };
  initializeApp(firebaseConfig);
}

const auth = getAuth();
if (process.env.REACT_APP_AUTH_EMULATOR_URL) {
  connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR_URL, { disableWarnings: true });
}

const DefaultLayoutEntry = (props) => {
  const [signedIn, setSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { setUserEmail, setUserRole } = useContext(LoginContext);

  async function signIn() {
    await signOut(auth);

    if (process.env.REACT_APP_AUTH_EMULATOR_URL) {
      // use cypress to set localStorage.TEST_LOGIN_CREDENTIAL to whatever needed
      const fakeCred = localStorage.getItem('TEST_LOGIN_CREDENTIAL') ||
        '{"sub": "foo_bar", "email": "foo@bar.net", "email_verified": true}';
      const cred = GoogleAuthProvider.credential(fakeCred);
      await signInWithCredential(auth, cred);
      return;
    }

    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      await signInWithPopup(auth, provider);
    } catch(err) {
      console.error(err);
    }
  }

  async function _signOut() {
    await signOut(auth);
  }

  // didmount
  useEffect(() => {
    // setup firebase login listener
    let initializingAuth = true;
    onIdTokenChanged(auth, async (user) => {
      //console.log('authStateChanged');

      if (user) {
        try {
          setIsLoading(true);

          // Add a request interceptor
          axios.interceptors.request.use(async function (config) {
            // Do something before request is sent
            const uri = axios.getUri(config);
            if (!isAbsoluteUrl(uri)) {
              const tokenId = await user.getIdToken();
              config.headers['authorization'] = 'Bearer ' + tokenId;
            }

            return config;
          }, function (error) {
            // Do something with request error
            return Promise.reject(error);
          });

          // call backend to check if email exists in our database
          const res = await axios.get('/api/me/profile');
          setUserEmail(res.data.email);
          setUserRole(res.data.role);

          setIsLoading(false);
          setSignedIn(true);
        } catch(err) {
          if (err?.response?.status === 401 && err?.response?.data?.message === 'UNKNOWN_EMAIL') {
            await signOut(auth);
          }

          notification['error']({
            message: 'Error',
            description: err?.response?.data?.message || err.message
          });
          setIsLoading(false);
        }
      } else {
        setSignedIn(false);
      }

      if (initializingAuth) {
        await new Promise(res => setTimeout(res, 300));
        initializingAuth = false;
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <>
      {isLoading ? <Loading></Loading> : null}
      <DefaultLayout isSignedIn={signedIn} onSignIn={signIn} onSignOut={_signOut}>{props.children}</DefaultLayout>
    </>
  )
}

export default DefaultLayoutEntry;
