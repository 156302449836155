import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from 'antd';
import axios from 'axios';
import BankEdit from "./BankEdit";
import * as dayjs from 'dayjs';
import Loading from '../components/Loading';


export const path = `/claim/:id/edit-bank`

const BankEditEntry = (props) => {
  const params = useParams();

  const [claimData, setClaimData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);

  const navigate = useNavigate();

  async function uploadMedia(file) {
    const { data: upload } = await axios.post('/api/upload/request', { filename: file.name });
    const config = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    await axios.put(upload.url, file, config)
    const mediaId = await axios.post('/api/upload/confirm', { key: upload.key }).then(res => res.data.id);

    return mediaId;
  }

  const handleUpdate = useCallback(async ({claimId,
    bankName,
    bankAccountNumber,
    bankAccountName,
    bankConfirmationFile,
    bankAccountRemarks,
  }) => {
    try {
      setIsLoading(true);
      const bank_confirmation_media_id = await uploadMedia(bankConfirmationFile.file.originFileObj);

      await axios.patch(
        `/api/claim/${claimId}/bank`,
        {
          bank_account_name: bankAccountName,
          bank_account_number: bankAccountNumber,
          bank_name: bankName,
          bank_confirmation_media_id,
          bank_account_remarks: bankAccountRemarks,
        },
      )
      navigate('/claims/incomplete-bank-detail')
    } catch (err) {
      notification['error']({
        message: 'Error',
        description: err?.response?.data?.message || err.message
      });
    } finally {
      setIsLoading(false)
    }

  }, []);


  const {
    claim,
    documents,
  } = useMemo(() => {
    if (!claimData) return {};

    function claimMapper(i) {
      return {
        id: i.id,
        status: i.status,
        assignee: i.assignee,
        submitter_email: i.submitter_email,
        policy_number: i.policy_number,
        policy_holder_name: i.policy_holder_name,
        insuree_id: i.insured_id,
        insuree_name: i.insured_name,
        insuree_contact: i.phone,
        insuree_bank_name: i.bank_name,
        insuree_bank_branch: i.bank_branch,
        insuree_bank_account: i.bank_account_number,
        insuree_bank_account_name: i.bank_account_name,
        insuree_email: i.email,
        type: i.type,
        treatment_date: `${dayjs(i.treatment_start).format('DD/MM/YYYY')} - ${dayjs(i.treatment_end).format('DD/MM/YYYY')}`,
        treatment_start: i.treatment_start,
        treatment_end: i.treatment_end,
        claimed_amount_formatted: (parseFloat(i.claimed_amount)).toLocaleString('th-TH', { style: 'currency', currency: 'THB' }),
        claimed_amount: i.claimed_amount,
        approved_amount: parseFloat(i.approved_amount).toLocaleString('th-TH', { style: 'currency', currency: 'THB' }),
        reject_reason: i.reject_reason,
        remarks: i.remarks,
        source: i.source,
        gisweb_id: i.gisweb_id,
        approver_email: i.approver_email,
        submitted_date: dayjs(i.created_at).format('DD/MM/YYYY'),
        reference_number: i.reference_number,
      };
    }

    function documentsMapper(i) {
      return {
        url: i.url,
        title: i.type
      };
    }

    const claim = claimMapper(claimData);
    const documents = claimData.medias.map(documentsMapper);
  
    return {
      claim,
      documents,
    };
  }, [claimData]);

  // fetching when needed
  useEffect(async () => {
    setIsLoading(true);
    const { data: { role } } = await axios.get('/api/me/profile');
    if (role !== 'CLAIM_SUPERVISOR' && role !== 'CLAIM_ADMIN') {
      navigate('/claims/incomplete-bank-detail')
    }
    const { data } = await axios.get('/api/claim/' + params.id);
    await new Promise(res => setTimeout(res, 300));
    setClaimData(data);
    setIsLoading(false);
  }, []);

  return (
    isLoading ? <Loading /> :
      <BankEdit 
        claim={claim}
        documents={documents}
        handleUpdate={handleUpdate}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
      />
    );

}

export default BankEditEntry;