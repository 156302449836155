export const CLAIM_STATUS = {
  SUBMITTED: 'SUBMITTED',
  IN_PROGRESS: 'IN_PROGRESS',
  DOCUMENT_APPROVED: 'DOCUMENT_APPROVED',
  DOCUMENT_INCOMPLETE: 'DOCUMENT_INCOMPLETE',
  FULLY_APPROVED: 'FULLY_APPROVED',
  PARTIALLY_APPROVED: 'PARTIALLY_APPROVED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  INCOMPLETE_BANK_DETAIL: 'INCOMPLETE_BANK_DETAIL',
  PENDING_SEND_TO_SUNCARE: 'PENDING_SEND_TO_SUNCARE',
  PENDING_REJECT: 'PENDING_REJECT',
  SENT_TO_SUNCARE: 'SENT_TO_SUNCARE',
};

export const CLAIM_STATUS_TH = {
  ...CLAIM_STATUS,
  SUBMITTED: 'สร้างใหม่\n(submitted)',
  DOCUMENT_APPROVED: 'เอกสารผ่านแล้ว\n(document approved)',
  DOCUMENT_INCOMPLETE: 'เอกสารไม่ครบ\n(document incomplete)',
  CANCELLED: 'ยกเลิก\n(cancelled)',
};

export const MISSING_DOC = {
  RECEIPT: 'RECEIPT',
  MEDICINE_SACHET: 'MEDICINE_SACHET',
  BOOKBANK: 'BOOKBANK',
  OTHER: 'OTHER',
};

export const MISSING_DOC_TH = {
  RECEIPT: 'ใบเสร็จรับเงิน',
  MEDICINE_SACHET: 'ใบรับรองแพทย์',
  BOOKBANK: 'สมุดบัญชี',
  OTHER: 'อื่น ๆ',
};

export const CLAIM_FILTER = {
  ALL: 'ALL',
  ...CLAIM_STATUS,
};

export const REJECT_REASONS = {
  NOT_COVERED: 'NOT_COVERED',
  EXCEPTION: 'EXCEPTION',
  FAX_CLAIM: 'FAX_CLAIM',
  DOCUMENT_INCOMPLETE: 'DOCUMENT_INCOMPLETE',
  OTHER: 'OTHER'
};

export const REJECT_REASONS_TH = {
  NOT_COVERED: 'กรมธรรม์ไม่มีความคุ้มครอง',
  EXCEPTION: 'ข้อยกเว้นทั่วไปของกรมธรรม์',
  FAX_CLAIM: 'ใช้สิทธิ์เบิกค่ารักษาพยาบาลผ่าน Fax claim แล้ว ไม่คุ้มครองค่าาชดเชยรายได้',
  OTHER: 'อื่น ๆ'
};

export const SEARCH_BY = {
  IDENTITY_NUMBER: 'identityNumber',
  POLICY_NUMBER: 'policyNumber',
  INSURED_NAME: 'insuredName',
  REFERENCE_NUMBER: 'referenceNumber',
  POLICY_HOLDER_NAME: 'policyHolderName',
  CLAIM_NUMBER: 'tpaClaimNumber',
};

export const CLAIM_TYPE = {
    OPD: 'OPD',
    IPD: 'IPD',
    DENTAL: 'DENTAL',
    VISION: 'VISION',
    PA: 'PA_MEDICAL',
    ER: 'ER',
    HB: 'HB',
    HB_INCENTIVE: 'HB_INCENTIVE',
};

export const BANK = {
  "BBL": {
    "name": {
      "code": "BBL",
      "localize": {
        "en": "Bangkok Bank",
        "th": "ธนาคารกรุงเทพ"
      }
    },
    "numCode": "002"
  },
  "KBANK": {
    "name": {
      "code": "KBANK",
      "localize": {
        "en": "Kasikorn Bank",
        "th": "ธนาคารกสิกรไทย"
      }
    },
    "numCode": "004"
  },
  "KTB": {
    "name": {
      "code": "KTB",
      "localize": {
        "en": "Krungthai Bank",
        "th": "ธนาคารกรุงไทย"
      }
    },
    "numCode": "006"
  },
  "TTB": {
    "name": {
      "code": "TTB",
      "localize": {
        "en": "TMBThanachart Bank",
        "th": "ธนาคารทหารไทยธนชาต"
      }
    },
    "numCode": "011"
  },
  "SCB": {
    "name": {
      "code": "SCB",
      "localize": {
        "en": "Siam Commercial Bank",
        "th": "ธนาคารไทยพาณิชย์"
      }
    },
    "numCode": "014"
  },
  "CITI": {
    "name": {
      "code": "CITI",
      "localize": {
        "en": "Citibank",
        "th": "ธนาคารซิตี้แบงก์"
      }
    },
    "numCode": "017"
  },
  "CIMB": {
    "name": {
      "code": "CIMB",
      "localize": {
        "en": "CIMB Thai",
        "th": "ธนาคารซีไอเอ็มบีไทย"
      }
    },
    "numCode": "022"
  },
  "UOBT": {
    "name": {
      "code": "UOBT",
      "localize": {
        "en": "United Overseas Bank",
        "th": "ธนาคารยูโอบี"
      }
    },
    "numCode": "024"
  },
  "BAY": {
    "name": {
      "code": "BAY",
      "localize": {
        "en": "Bank of Ayudthaya (Krungsri)",
        "th": "ธนาคารกรุงศรีอยุธยา"
      }
    },
    "numCode": "025"
  },
  "ISBT": {
    "name": {
      "code": "ISBT",
      "localize": {
        "en": "Islamic Bank of Thailand",
        "th": "ธนาคารอิสลามแห่งประเทศไทย"
      }
    },
    "numCode": "066"
  },
  "LHB": {
    "name": {
      "code": "LHB",
      "localize": {
        "en": "Land and Houses Bank",
        "th": "ธนาคารแลนด์ แอนด์ เฮาส์"
      }
    },
    "numCode": "073"
  },
  "SC": {
    "name": {
      "code": "SC",
      "localize": {
        "en": "Standard Chartered (Thai)",
        "th": "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)"
      }
    },
    "numCode": "020"
  },
  "GSB": {
    "name": {
      "code": "GSB",
      "localize": {
        "en": "Government Savings Bank",
        "th": "ธนาคารออมสิน"
      }
    },
    "numCode": "030"
  },
  "HSBC": {
    "name": {
      "code": "HSBC",
      "localize": {
        "en": "Hongkong and Shanghai Banking Corporation",
        "th": "ธนาคาร เอช เอส บี ซี"
      }
    },
    "numCode": "031"
  },
  "GHB": {
    "name": {
      "code": "GHB",
      "localize": {
        "en": "Government Housing Bank",
        "th": "ธนาคารอาคารสงเคราะห์"
      }
    },
    "numCode": "033"
  },
  "BAAC": {
    "name": {
      "code": "BAAC",
      "localize": {
        "en": "Bank for Agriculture and Agricultural Cooperatives",
        "th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
      }
    },
    "numCode": "034"
  },
  "TISCO": {
    "name": {
      "code": "TISCO",
      "localize": {
        "en": "Tisco Bank",
        "th": "ธนาคารทิสโก้"
      }
    },
    "numCode": "067"
  },
  "KKP": {
    "name": {
      "code": "KKP",
      "localize": {
        "en": "Kiatnakin Phatra Bank",
        "th": "ธนาคารเกียรตินาคินภัทร"
      }
    },
    "numCode": "069"
  },
  "TCD": {
    "name": {
      "code": "TCD",
      "localize": {
        "en": "Thai Credit Retail Bank",
        "th": "ธนาคารไทยเครดิตเพื่อรายย่อย"
      }
    },
    "numCode": "071"
  },
  "SME": {
    "name": {
      "code": "SME",
      "localize": {
        "en": "Small and Medium Enterprise Development Bank of Thailand",
        "th": "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย"
      }
    },
    "numCode": "098"
  },
  "EXIM": {
    "name": {
      "code": "EXIM",
      "localize": {
        "en": "Export-Import Bank of Thailand",
        "th": "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย"
      }
    },
    "numCode": "035"
  },
  "ICBC": {
    "name": {
      "code": "ICBC",
      "localize": {
        "en": "ICBC (Thai)",
        "id": "ICBC (Thai)",
        "th": "ธนาคารไอซีบีซี (ไทย)"
      }
    },
    "numCode": "070"
  }
}