import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Input, Select, Table, Radio, Space } from 'antd';

import styles from './ClaimList.module.scss';

import { isEmpty } from 'lodash/fp';
import Loading from '../components/Loading';


const IncompleteBankClaimList = ({ claimData }) => {
  const columns = [
    {
      title: 'วันที่สร้าง', // 'Submitted At'
      dataIndex: 'submitted_at'
    },
    {
      title: 'เลขกรมธรรม์', // 'Policy Number'
      dataIndex: 'policy_number'
    },
    {
      title: 'เลข Reference', // 'Ref. Number'
      dataIndex: 'reference_number'
    },
    {
      title: 'ชื่อผู้ถือกรมธรรม์', // 'Policy Holder Name'
      dataIndex: 'policy_holder_name'
    },
    {
      title: 'ผู้รับความคุ้มครอง', // 'Insured'
      dataIndex: 'insured_name'
    },
    {
      title: 'เลขบัตรปชช.', // 'Identification'
      dataIndex: 'insured_id'
    },
    {
      title: 'วันเข้ารักษา', // 'Treatement Date'
      dataIndex: "treatment_date"
    },
    {
      title: 'ธนาคาร', // 'Bank name'
      dataIndex: 'bank_name'
    },
    {
      title: 'เลขที่บัญชี', // 'Bank account number'
      dataIndex: 'bank_account_number'
    },
    {
      title: 'ชื่อบัญชี',
      dataIndex: 'bank_account_name'
    },
    {
      title: '-',
      dataIndex: 'detail_link',
      render(text, record, index) {
        return (
          <Link to={text} data-cy="claim-link">{'ดูรายละเอียด' /* detail */ }</Link>
        )
      }

    }
  ];

  return (
    <>
     <div className={styles.table}>
      <Table pagination={false} columns={columns} dataSource={claimData} data-cy="claims-table"></Table>
    </div>
    </>
  );
};

IncompleteBankClaimList.propTypes = {
};

IncompleteBankClaimList.defaultProps = {
};

export default IncompleteBankClaimList;