import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Input, Select, Table, Radio, Space, Button } from 'antd';

import styles from './ClaimList.module.scss';
import { CLAIM_FILTER, SEARCH_BY, CLAIM_STATUS_TH } from '../utils/constant';
import { isEmpty, isNil } from 'lodash/fp';

const ClaimList = ({ claims, pagination, status, searchText, searchOption, statusChangeHandler, searchHandler, downloadRejectLetterHandler }) => {
  const [searchOpt, setSearchOpt] = useState(searchOption || SEARCH_BY.IDENTITY_NUMBER);

  const columns = [
    {
      title: 'วันที่สร้าง', // 'Submitted At'
      dataIndex: 'submitted_at'
    },
    {
      title: 'วันที่ reject',
      dataIndex: 'rejected_date',
    },
    {
      title: 'เลขกรมธรรม์.', // 'Policy Number'
      dataIndex: 'policy_number'
    },
    {
      title: 'เลขเคลม', // 'Ref. Number'
      dataIndex: 'tpa_claim_number'
    },
    {
      title: 'ชื่อผู้ถือกรมธรรม์', // 'Policy Holder Name'
      dataIndex: 'policy_holder_name'
    },
    {
      title: 'ผู้รับความคุ้มครอง', // 'Insured'
      dataIndex: 'insured_name'
    },
    {
      title: 'เลขบัตรปชช.', // 'Identification'
      dataIndex: 'insured_id'
    },
    {
      title: 'วันเข้ารักษา', // 'Treatement Date'
      dataIndex: "treatment_date"
    },
    {
      title: 'ยอดเคลม', // 'Claim Amount'
      dataIndex: 'claim_amount'
    },
    {
      title: 'สถานะ',
      dataIndex: 'status'
    },
    {
      title: 'Reject letter',
      dataIdnex: 'reject_request_id',
      render(text, record, index) {
        return record.status === CLAIM_STATUS_TH.REJECTED && (<Button type="default" onClick={() => downloadRejectLetterHandler(record.reject_request_id) }>
        Download
      </Button>)
      }
    },
    {
      title: '-',
      dataIndex: 'detail_link',
      render(text, record, index) {
        return (
          <Link to={text} data-cy="claim-link">{'ดูรายละเอียด' /* detail */ }</Link>
        )
      }

    }
  ];

  const selectBefore = (
    <Select defaultValue={searchOpt} onChange={setSearchOpt}>
      <Select.Option value={SEARCH_BY.POLICY_NUMBER}>{'เลขกรมธรรม์' /* Policy number */ }</Select.Option>
      <Select.Option value={SEARCH_BY.IDENTITY_NUMBER}>{'เลขบัตรปชช.' /* Identification number */ }</Select.Option>
      <Select.Option value={SEARCH_BY.CLAIM_NUMBER}>{'เลขเคลม.' /* Identification number */ }</Select.Option>
      <Select.Option value={SEARCH_BY.REFERENCE_NUMBER}>{'เลข Reference' /* Reference number */ }</Select.Option>
      <Select.Option value={SEARCH_BY.POLICY_HOLDER_NAME}>{'ชื่อผู้ถือกรมธรรม์' /* policy holder name */ }</Select.Option>
      <Select.Option value={SEARCH_BY.INSURED_NAME}>{'ชื่อผู้ทำประกัน' /* Insured name */ }</Select.Option>
    </Select>
  );

  return (
    <>
      <div className={styles.searchWrapper}>
        <Input.Search addonBefore={selectBefore} onSearch={(text) => searchHandler(text, searchOpt)} defaultValue={searchText}></Input.Search>
      </div>
      <div className={styles.bodyAndSidebar}>
        <div className={styles.sidebar}>
        <div className={styles.sidebarTopic}>{'สถานะ' /* Status */ }</div>
        <Radio.Group onChange={(e) => statusChangeHandler(e.target.value)} value={isEmpty(status) ? CLAIM_FILTER.ALL : status}>
          <Space direction="vertical">
            <Radio value={CLAIM_FILTER.ALL}>ทั้งหมด</Radio>
            <Radio value={CLAIM_FILTER.SENT_TO_SUNCARE}>{CLAIM_STATUS_TH.SENT_TO_SUNCARE}</Radio>
            <Radio value={CLAIM_FILTER.INCOMPLETE_BANK_DETAIL}>{CLAIM_STATUS_TH.INCOMPLETE_BANK_DETAIL}</Radio>
            <Radio value={CLAIM_FILTER.PENDING_SEND_TO_SUNCARE}>{CLAIM_STATUS_TH.PENDING_SEND_TO_SUNCARE}</Radio>
            <Radio value={CLAIM_FILTER.PENDING_REJECT}>{CLAIM_STATUS_TH.PENDING_REJECT}</Radio>
            <Radio value={CLAIM_FILTER.REJECTED}>{CLAIM_STATUS_TH.REJECTED}</Radio>
          </Space>
        </Radio.Group>
        </div>
        <div>
          <Link to={'/claim/new'}>{'สร้างเคลม' /* New Claim */ }</Link>
          <div className={styles.table}>
            <Table pagination={false} columns={columns} dataSource={claims} data-cy="claims-table"></Table>
          </div>
          {pagination}
        </div>
      </div>
    </>
  );
};

ClaimList.propTypes = {
  claims: PropTypes.array
};

ClaimList.defaultProps = {
};

export default ClaimList;