import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { notification, Form } from 'antd';

import ClaimNew from "./ClaimNew";
import Loading from '../components/Loading';
import { CLAIM_STATUS, CLAIM_TYPE } from "../utils/constant";

export const path = '/claim/new';

const ClaimNewEntry = (props) => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  async function uploadMedia(file) {
    const upload = await axios.post('/api/upload/request', { filename: file.name }).then(res => res.data);
    const config = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    await axios.put(upload.url, file, config).then(res => res.data.id);
    const mediaId = await axios.post('/api/upload/confirm', { key: upload.key }).then(res => res.data.id);

    return mediaId;
  }

  async function submitClaim({
    policy_number,
    type,
    insured_name,
    insured_nationality,
    insured_id,
    phone,
    email,
    claimed_amount,
    treatment_start,
    treatment_end,
    receipt_file,
    med_cert_file,
    bank_account_file,
    other_file_1,
    other_file_2,
    other_file_3,
  }) {
    try {
      setIsLoading(true);
      const receipt_media_id = await uploadMedia(receipt_file.file.originFileObj);
      const medical_certificate_media_id = await uploadMedia(med_cert_file.file.originFileObj);

      const medias = {
        receipt_media_id,
        medical_certificate_media_id,
        other_media_ids: [],
      };

      if (bank_account_file?.file) {
        medias.bank_account_media_id = await uploadMedia(bank_account_file.file.originFileObj);
      }

      if (other_file_1?.file) {
        medias.other_media_ids.push(await uploadMedia(other_file_1.file.originFileObj))
      }
      if (other_file_2?.file) {
        medias.other_media_ids.push(await uploadMedia(other_file_2.file.originFileObj))
      }
      if (other_file_3?.file) {
        medias.other_media_ids.push(await uploadMedia(other_file_3.file.originFileObj))
      }

      const data = {
        policy_number,
        type,
        insured_name,
        insured_nationality,
        insured_id,
        phone,
        email,
        claimed_amount,
        treatment_start,
        treatment_end: type === CLAIM_TYPE.IPD ? treatment_end : treatment_start,
        ...medias
      };
      const claim = await axios.post('/api/claim', data).then(res => res.data);
      if (claim.status === CLAIM_STATUS.INCOMPLETE_BANK_DETAIL) {
        navigate(`/claim/${claim.id}/edit-bank`)
      } else {
        navigate(`/claim/${claim.id}`);
      }
    } catch(err) {
      setIsLoading(false);
      notification['error']({
        message: 'Error',
        description: err?.response?.data?.error || err.message
      });
    }
  }

  return (
    <>
      {isLoading ? <Loading></Loading> : null}
      <ClaimNew submitClaim={submitClaim} form={form}></ClaimNew>
    </>
  );
}

export default ClaimNewEntry;